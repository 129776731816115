import { Flex } from '@applyboard/crystal-ui';
import { BackButton } from '~/components/BackButton';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { ApplicationDetailsTabs } from '../components/ApplicationDetailsTabs/ApplicationDetailsTabs';

import {
  ProgramDetails,
  ApplicationDetailsHeading,
  StatusAndCitizenship,
  ContactInformation,
  ApplicantInformation,
  EducationHistory,
  LanguageProficiency,
  OtherRequiredDocuments,
  ApplicationProcessing,
  CardControlActions,
  DecisionLetters,
  UploadAdditionalDocuments,
  // processing
  AdditionalDocumentNotice,
  DeferralRequestNotice,
} from './../components';

export function MobileLayout() {
  const { application } = useAppProcessing();
  const applicationStatus =
    application.status.code === 'DECLINED' ||
    application.status.code === 'INITIAL_OFFER' ||
    application.status.code === 'WAITLISTED' ||
    application.status.code === 'FINAL_OFFER_ISSUED';
  const shouldRenderAIMLFeature = Boolean(application.statusAndCitizenship.extractedInfo);
  function applicationDetailsLayout() {
    return (
      <Flex gap={8} align="stretch" direction="column">
        <Flex gap={8} direction="column">
          <ApplicantInformation />
          <ContactInformation />
          {!shouldRenderAIMLFeature && <StatusAndCitizenship />}
          <EducationHistory />
          <LanguageProficiency />
          <OtherRequiredDocuments />
        </Flex>
      </Flex>
    );
  }
  return (
    <div aria-label="mobile" style={{ marginBottom: '10px' }}>
      <Flex pt={10} pb={2} px={8} gap={6} direction="column">
        <BackButton title="Applications List" href="/applications" />
      </Flex>
      <Flex px={8} gap={6} direction="column">
        <Flex justify="between" align="stretch" direction="column">
          <ApplicationDetailsHeading />
          <ApplicationProcessing />
        </Flex>
        <DeferralRequestNotice />
        <AdditionalDocumentNotice />
        <ProgramDetails lineSplit={true} />
        <CardControlActions />

        {applicationStatus ? (
          <ApplicationDetailsTabs
            submittedApplicationDetails={applicationDetailsLayout()}
            applicationDetails={
              <Flex gap={8} align="stretch" direction="column">
                <DecisionLetters />
                <UploadAdditionalDocuments />
              </Flex>
            }
          />
        ) : (
          applicationDetailsLayout()
        )}
      </Flex>
    </div>
  );
}
