import React from 'react';
import { Flag } from '~/components/Flag';
import { formatNonTimezoneDate } from '~/utils';
import { DocumentCheckIcon, PersonOutlineIcon } from '@applyboard/ui-icons';
import { LabelValue } from '~/components/uie-fix/LabelValue';
import { AccordionWithIntent } from '~/components/uie-fix/AccordionWithIntent';
import { Card as CrystalCard, Flex, Heading, Text, GridAlpha, Link, Tag, HelpText } from '@applyboard/crystal-ui';
import { useAppProcessing } from '~/pages/ApplicationDetails/context';
import { ApplicantAddress, StatusAndCitizenship } from '~/pages/ApplicationDetails/data/useApplication';

import { Card } from './Card';
import { AttachedDocuments } from './shared/AttachedDocuments';
import { VisaOutcomeRequest } from '../ApplicationProcessing/VisaOutcome/VisaOutcomeRequest';

function ApplicantInfo() {
  const {
    application: { applicant, statusAndCitizenship },
  } = useAppProcessing();
  const shouldRenderAIMLFeature = Boolean(statusAndCitizenship.extractedInfo);

  return shouldRenderAIMLFeature ? (
    <GridAlpha rows="repeat(3, auto)" columnGap={2} rowGap={6} columns="repeat(3, 1fr)">
      <LabelValue label="GIVEN NAME" value={applicant.givenName} />
      <LabelValue label="FAMILY NAME" value={applicant.familyName || '--'} />
      <LabelValue label="MIDDLE NAME" value={applicant.middleName || '--'} />

      <LabelValue label="DATE OF BIRTH" value={formatNonTimezoneDate(new Date(applicant.dateOfBirth))} />
      <LabelValue label="GENDER" value={applicant.gender.name} />
      <LabelValue
        label="NATIONALITY"
        value={applicant.nationality.nationalityLabel}
        icon={<Flag height={10} nationalityCode={applicant.nationality.alpha2Code} />}
      />
      <LabelValue label="SUPPORTING DOC" value="Passport" />
    </GridAlpha>
  ) : (
    <GridAlpha rows="repeat(3, auto)" columnGap={2} rowGap={6} columns="repeat(3, 1fr)">
      <LabelValue label="GIVEN NAME" value={applicant.givenName} />
      <LabelValue label="FAMILY NAME" value={applicant.familyName || '--'} />
      <LabelValue label="MIDDLE NAME" value={applicant.middleName || '--'} />

      <LabelValue label="DATE OF BIRTH" value={formatNonTimezoneDate(new Date(applicant.dateOfBirth))} />
      <LabelValue label="GENDER" value={applicant.gender.name} />
      <LabelValue
        label="NATIONALITY"
        value={applicant.nationality.nationalityLabel}
        icon={<Flag height={10} nationalityCode={applicant.nationality.alpha2Code} />}
      />
    </GridAlpha>
  );
}
function AddressDetails(props: { heading: string; address: ApplicantAddress }) {
  const { address } = props;
  return (
    <Flex gap={8} direction="column" role="region" aria-describedby={props.heading}>
      <Heading level={4} variant="titleS">
        {props.heading}
      </Heading>
      <GridAlpha rows="repeat(1, auto)" columnGap={2} rowGap={6} columns="repeat(3, 1fr)">
        <GridAlpha.Item rowStart={1} columnStart={1} columnEnd={2}>
          <LabelValue label="COUNTRY" value={address.country.countryLabel} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={2} columnStart={1} columnEnd={1}>
          <LabelValue label="ADDRESS LINE 1" value={address.addressLine1} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={2} columnStart={2} columnEnd={2}>
          <LabelValue label="ADDRESS LINE 2" value={address.addressLine2 || '--'} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={3} columnStart={1} columnEnd={1}>
          <LabelValue label="CITY" value={address.city} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={3} columnStart={2} columnEnd={2}>
          <LabelValue label="PROVINCE/STATE/AREA" value={address.province} />
        </GridAlpha.Item>
        <GridAlpha.Item rowStart={4} columnStart={1} columnEnd={2}>
          <LabelValue label="POSTAL/ZIP CODE" value={address.postalCode || '--'} />
        </GridAlpha.Item>
      </GridAlpha>
    </Flex>
  );
}

type AiExtractionScenario = {
  title: string;
  tagText: string;
  intent: 'positive' | 'negative' | 'warning';
  message: string;
};

type AiExtractionScenarios = {
  failed: AiExtractionScenario;
  success: AiExtractionScenario;
  pending: AiExtractionScenario;
};

function AIExtractedPassportReport(props: { statusAndCitizenship: StatusAndCitizenship }) {
  const { statusAndCitizenship } = props;
  if (statusAndCitizenship.extractedInfo?.status === undefined) {
    return;
  } else {
    const aiExtractionScenarios: AiExtractionScenarios = {
      success: {
        title: 'Success',
        tagText: 'Consistent',
        intent: 'positive',
        message: 'Document classification is the expected one.',
      },
      failed: {
        title: 'Fail',
        tagText: 'Inconsistent',
        intent: 'negative',
        message: 'Document classification is not the expected one.',
      },
      pending: {
        title: 'Needs attention',
        tagText: 'Needs Review',
        intent: 'warning',
        message: 'Not all information or document classification matches.',
      },
    };

    const currentAiScenario = aiExtractionScenarios[statusAndCitizenship.extractedInfo.status];

    return (
      <CrystalCard>
        <Text variant="labelM">Attached file</Text>
        <Flex pt={6} pb={8} align="center">
          <Text variant="labelL" intent="positive">
            <Link
              href={statusAndCitizenship.documents[0].downloadUrl as string}
              mode="override_user_choice_and_open_in_new_tab"
            >
              <Flex gap={2}>
                <DocumentCheckIcon variant="outline" />
                {statusAndCitizenship.documents[0].fileName}
              </Flex>
            </Link>
          </Text>
        </Flex>
        <Text variant="labelM">AI-Powered results</Text>
        <AccordionWithIntent
          id="extracted_info_status_accordion"
          title={`Result: ${currentAiScenario.title}`}
          intent={currentAiScenario.intent}
        >
          <Flex direction="column" align="start" gap={2}>
            <HelpText>
              <span
                style={{
                  fontSize: '12px',
                  fontFamily: 'Montserrat',
                }}
              >
                DATA ACCURACY
              </span>
            </HelpText>
            <Tag intent={currentAiScenario.intent} size="md">
              {currentAiScenario.tagText}
            </Tag>
            <Text variant="bodyM" intent="secondary">
              {currentAiScenario.message}
            </Text>
          </Flex>
        </AccordionWithIntent>
      </CrystalCard>
    );
  }
}

export function ApplicantInformation() {
  const {
    application: { applicant, statusAndCitizenship },
  } = useAppProcessing();
  const isMailingAddressSameAsResidential = applicant.addresses.isMailingAddressSameAsResidential;
  const shouldRenderAIMLFeature = Boolean(statusAndCitizenship.extractedInfo);
  return (
    <>
      <Card id="applicant" icon={PersonOutlineIcon} title="Applicant Information">
        <Flex gap={8} direction="column">
          <ApplicantInfo />
          <AIExtractedPassportReport statusAndCitizenship={statusAndCitizenship} />
          <AddressDetails heading="Residential Address" address={applicant.addresses.residentialAddress} />
          {isMailingAddressSameAsResidential ? (
            <Text>Mailing address is the same as shipping address.</Text>
          ) : (
            <AddressDetails heading="Mailing Address" address={applicant.addresses.mailingAddress} />
          )}
          {shouldRenderAIMLFeature && (
            <>
              <Heading level={4} variant="titleS">
                Status Information
              </Heading>
              <LabelValue label="CANADA STATUS" value={statusAndCitizenship.destinationCountryStatus.name} />
              <AttachedDocuments
                disableAdditionalRequest
                attachmentDocuments={Object.values(statusAndCitizenship.documents)}
                showTitle
                section="statusAndCitizenship"
                sectionReference={statusAndCitizenship.key}
                noRequestForMissingDocuments
                types={[
                  'DESTINATION_COUNTRY_STATUS',
                  'STUDY_PERMIT',
                  'WORK_PERMIT',
                  'VISITOR_TOURIST_VISA',
                  'VISA_OUTCOME',
                ]}
              />
              <VisaOutcomeRequest />
            </>
          )}
        </Flex>
      </Card>
    </>
  );
}
